const data = [
  {
    code: "AF",
    map: "🇦🇫",
    phoneCode: 93,
    label: "Afghanistan",
  },
  {
    code: "AL",
    map: "🇦🇱",
    phoneCode: 355,
    label: "Albania",
  },
  {
    code: "DZ",
    map: "🇩🇿",
    phoneCode: 213,
    label: "Algeria",
  },
  {
    code: "AS",
    map: "🇦🇸",
    phoneCode: 1684,
    label: "American Samoa",
  },
  {
    code: "AD",
    map: "🇦🇩",
    phoneCode: 376,
    label: "Andorra",
  },
  {
    code: "AO",
    map: "🇦🇴",
    phoneCode: 244,
    label: "Angola",
  },
  {
    code: "AI",
    map: "🇦🇮",
    phoneCode: 1264,
    label: "Anguilla",
  },
  {
    code: "AQ",
    map: "🇦🇶",
    phoneCode: 0,
    label: "Antarctica",
  },
  {
    code: "AG",
    map: "🇦🇬",
    phoneCode: 1268,
    label: "Antigua and Barbuda",
  },
  {
    code: "AR",
    map: "🇦🇷",
    phoneCode: 54,
    label: "Argentina",
  },
  {
    code: "AM",
    map: "🇦🇲",
    phoneCode: 374,
    label: "Armenia",
  },
  {
    code: "AW",
    map: "🇦🇼",
    phoneCode: 297,
    label: "Aruba",
  },
  {
    code: "AU",
    map: "🇦🇺",
    phoneCode: 61,
    label: "Australia",
  },
  {
    code: "AT",
    map: "🇦🇹",
    phoneCode: 43,
    label: "Austria",
  },
  {
    code: "AZ",
    map: "🇦🇿",
    phoneCode: 994,
    label: "Azerbaijan",
  },
  {
    code: "BS",
    map: "🇧🇸",
    phoneCode: 1242,
    label: "Bahamas",
  },
  {
    code: "BH",
    map: "🇧🇭",
    phoneCode: 973,
    label: "Bahrain",
  },
  {
    code: "BD",
    map: "🇧🇩",
    phoneCode: 880,
    label: "Bangladesh",
  },
  {
    code: "BB",
    map: "🇧🇧",
    phoneCode: 1246,
    label: "Barbados",
  },
  {
    code: "BY",
    map: "🇧🇾",
    phoneCode: 375,
    label: "Belarus",
  },
  {
    code: "BE",
    map: "🇧🇪",
    phoneCode: 32,
    label: "Belgium",
  },
  {
    code: "BZ",
    map: "🇧🇿",
    phoneCode: 501,
    label: "Belize",
  },
  {
    code: "BJ",
    map: "🇧🇯",
    phoneCode: 229,
    label: "Benin",
  },
  {
    code: "BM",
    map: "🇧🇲",
    phoneCode: 1441,
    label: "Bermuda",
  },
  {
    code: "BT",
    map: "🇧🇹",
    phoneCode: 975,
    label: "Bhutan",
  },
  {
    code: "BO",
    map: "🇧🇴",
    phoneCode: 591,
    label: "Bolivia",
  },
  {
    code: "BA",
    map: "🇧🇦",
    phoneCode: 387,
    label: "Bosnia and Herzegovina",
  },
  {
    code: "BW",
    map: "🇧🇼",
    phoneCode: 267,
    label: "Botswana",
  },
  {
    code: "BV",
    map: "🇧🇻",
    phoneCode: 0,
    label: "Bouvet Island",
  },
  {
    code: "BR",
    map: "🇧🇷",
    phoneCode: 55,
    label: "Brazil",
  },
  {
    code: "IO",
    map: "🇮🇴",
    phoneCode: 246,
    label: "British Indian Ocean Territory",
  },
  {
    code: "BN",
    map: "🇧🇳",
    phoneCode: 673,
    label: "Brunei",
  },
  {
    code: "BG",
    map: "🇧🇬",
    phoneCode: 359,
    label: "Bulgaria",
  },
  {
    code: "BF",
    map: "🇧🇫",
    phoneCode: 226,
    label: "Burkina Faso",
  },
  {
    code: "BI",
    map: "🇧🇮",
    phoneCode: 257,
    label: "Burundi",
  },
  {
    code: "KH",
    map: "🇰🇭",
    phoneCode: 855,
    label: "Cambodia",
  },
  {
    code: "CM",
    map: "🇨🇲",
    phoneCode: 237,
    label: "Cameroon",
  },
  {
    code: "CA",
    map: "🇨🇦",
    phoneCode: 1,
    label: "Canada",
  },
  {
    code: "CV",
    map: "🇨🇻",
    phoneCode: 238,
    label: "Cape Verde",
  },
  {
    code: "KY",
    map: "🇰🇾",
    phoneCode: 1345,
    label: "Cayman Islands",
  },
  {
    code: "CF",
    map: "🇨🇫",
    phoneCode: 236,
    label: "Central African Republic",
  },
  {
    code: "TD",
    map: "🇹🇩",
    phoneCode: 235,
    label: "Chad",
  },
  {
    code: "CL",
    map: "🇨🇱",
    phoneCode: 56,
    label: "Chile",
  },
  {
    code: "CN",
    map: "🇨🇳",
    phoneCode: 86,
    label: "China",
  },
  {
    code: "CX",
    map: "🇨🇽",
    phoneCode: 61,
    label: "Christmas Island",
  },
  {
    code: "CC",
    map: "🇨🇨",
    phoneCode: 672,
    label: "Cocos (Keeling) Islands",
  },
  {
    code: "CO",
    map: "🇨🇴",
    phoneCode: 57,
    label: "Colombia",
  },
  {
    code: "KM",
    map: "🇰🇲",
    phoneCode: 269,
    label: "Comoros",
  },
  {
    code: "CK",
    map: "🇨🇰",
    phoneCode: 682,
    label: "Cook Islands",
  },
  {
    code: "CR",
    map: "🇨🇷",
    phoneCode: 506,
    label: "Costa Rica",
  },
  {
    code: "HR",
    map: "🇭🇷",
    phoneCode: 385,
    label: "Croatia",
  },
  {
    code: "CU",
    map: "🇨🇺",
    phoneCode: 53,
    label: "Cuba",
  },
  {
    code: "CY",
    map: "🇨🇾",
    phoneCode: 357,
    label: "Cyprus",
  },
  {
    code: "CZ",
    map: "🇨🇿",
    phoneCode: 420,
    label: "Czech Republic",
  },
  {
    code: "CD",
    map: "🇨🇩",
    phoneCode: 243,
    label: "Democratic Republic of the Congo",
  },
  {
    code: "DK",
    map: "🇩🇰",
    phoneCode: 45,
    label: "Denmark",
  },
  {
    code: "DJ",
    map: "🇩🇯",
    phoneCode: 253,
    label: "Djibouti",
  },
  {
    code: "DM",
    map: "🇩🇲",
    phoneCode: 1767,
    label: "Dominica",
  },
  {
    code: "DO",
    map: "🇩🇴",
    phoneCode: 1809,
    label: "Dominican Republic",
  },
  {
    code: "TL",
    map: "🇹🇱",
    phoneCode: 670,
    label: "East Timor",
  },
  {
    code: "EC",
    map: "🇪🇨",
    phoneCode: 593,
    label: "Ecuador",
  },
  {
    code: "EG",
    map: "🇪🇬",
    phoneCode: 20,
    label: "Egypt",
  },
  {
    code: "SV",
    map: "🇸🇻",
    phoneCode: 503,
    label: "El Salvador",
  },
  {
    code: "GQ",
    map: "🇬🇶",
    phoneCode: 240,
    label: "Equatorial Guinea",
  },
  {
    code: "ER",
    map: "🇪🇷",
    phoneCode: 291,
    label: "Eritrea",
  },
  {
    code: "EE",
    map: "🇪🇪",
    phoneCode: 372,
    label: "Estonia",
  },
  {
    code: "SZ",
    map: "🇸🇿",
    phoneCode: 268,
    label: "Eswatini",
  },
  {
    code: "ET",
    map: "🇪🇹",
    phoneCode: 251,
    label: "Ethiopia",
  },
  {
    code: "FK",
    map: "🇫🇰",
    phoneCode: 500,
    label: "Falkland Islands",
  },
  {
    code: "FO",
    map: "🇫🇴",
    phoneCode: 298,
    label: "Faroe Islands",
  },
  {
    code: "FJ",
    map: "🇫🇯",
    phoneCode: 679,
    label: "Fiji",
  },
  {
    code: "FI",
    map: "🇫🇮",
    phoneCode: 358,
    label: "Finland",
  },
  {
    code: "FR",
    map: "🇫🇷",
    phoneCode: 33,
    label: "France",
  },
  {
    code: "GF",
    map: "🇬🇫",
    phoneCode: 594,
    label: "French Guiana",
  },
  {
    code: "PF",
    map: "🇵🇫",
    phoneCode: 689,
    label: "French Polynesia",
  },
  {
    code: "TF",
    map: "🇹🇫",
    phoneCode: 0,
    label: "French Southern Territories",
  },
  {
    code: "GA",
    map: "🇬🇦",
    phoneCode: 241,
    label: "Gabon",
  },
  {
    code: "GM",
    map: "🇬🇲",
    phoneCode: 220,
    label: "Gambia",
  },
  {
    code: "GE",
    map: "🇬🇪",
    phoneCode: 995,
    label: "Georgia",
  },
  {
    code: "DE",
    map: "🇩🇪",
    phoneCode: 49,
    label: "Germany",
  },
  {
    code: "GH",
    map: "🇬🇭",
    phoneCode: 233,
    label: "Ghana",
  },
  {
    code: "GI",
    map: "🇬🇮",
    phoneCode: 350,
    label: "Gibraltar",
  },
  {
    code: "GR",
    map: "🇬🇷",
    phoneCode: 30,
    label: "Greece",
  },
  {
    code: "GL",
    map: "🇬🇱",
    phoneCode: 299,
    label: "Greenland",
  },
  {
    code: "GD",
    map: "🇬🇩",
    phoneCode: 1473,
    label: "Grenada",
  },
  {
    code: "GP",
    map: "🇬🇵",
    phoneCode: 590,
    label: "Guadeloupe",
  },
  {
    code: "GU",
    map: "🇬🇺",
    phoneCode: 1671,
    label: "Guam",
  },
  {
    code: "GT",
    map: "🇬🇹",
    phoneCode: 502,
    label: "Guatemala",
  },
  {
    code: "GN",
    map: "🇬🇳",
    phoneCode: 224,
    label: "Guinea",
  },
  {
    code: "GW",
    map: "🇬🇼",
    phoneCode: 245,
    label: "Guinea-Bissau",
  },
  {
    code: "GY",
    map: "🇬🇾",
    phoneCode: 592,
    label: "Guyana",
  },
  {
    code: "HT",
    map: "🇭🇹",
    phoneCode: 509,
    label: "Haiti",
  },
  {
    code: "HN",
    map: "🇭🇳",
    phoneCode: 504,
    label: "Honduras",
  },
  {
    code: "HU",
    map: "🇭🇺",
    phoneCode: 36,
    label: "Hungary",
  },
  {
    code: "IS",
    map: "🇮🇸",
    phoneCode: 354,
    label: "Iceland",
  },
  {
    code: "IN",
    map: "🇮🇳",
    phoneCode: 91,
    label: "India",
  },
  {
    code: "ID",
    map: "🇮🇩",
    phoneCode: 62,
    label: "Indonesia",
  },
  {
    code: "IR",
    map: "🇮🇷",
    phoneCode: 98,
    label: "Iran",
  },
  {
    code: "IQ",
    map: "🇮🇶",
    phoneCode: 964,
    label: "Iraq",
  },
  {
    code: "IE",
    map: "🇮🇪",
    phoneCode: 353,
    label: "Ireland",
  },
  {
    code: "IL",
    map: "🇮🇱",
    phoneCode: 972,
    label: "Israel",
  },
  {
    code: "IT",
    map: "🇮🇹",
    phoneCode: 39,
    label: "Italy",
  },
  {
    code: "CI",
    map: "🇨🇮",
    phoneCode: 225,
    label: "Ivory Coast",
  },
  {
    code: "JM",
    map: "🇯🇲",
    phoneCode: 1876,
    label: "Jamaica",
  },
  {
    code: "JP",
    map: "🇯🇵",
    phoneCode: 81,
    label: "Japan",
  },
  {
    code: "JO",
    map: "🇯🇴",
    phoneCode: 962,
    label: "Jordan",
  },
  {
    code: "KZ",
    map: "🇰🇿",
    phoneCode: 7,
    label: "Kazakhstan",
  },
  {
    code: "KE",
    map: "🇰🇪",
    phoneCode: 254,
    label: "Kenya",
  },
  {
    code: "KI",
    map: "🇰🇮",
    phoneCode: 686,
    label: "Kiribati",
  },
  {
    code: "KW",
    map: "🇰🇼",
    phoneCode: 965,
    label: "Kuwait",
  },
  {
    code: "KG",
    map: "🇰🇬",
    phoneCode: 996,
    label: "Kyrgyzstan",
  },
  {
    code: "LA",
    map: "🇱🇦",
    phoneCode: 856,
    label: "Laos",
  },
  {
    code: "LV",
    map: "🇱🇻",
    phoneCode: 371,
    label: "Latvia",
  },
  {
    code: "LB",
    map: "🇱🇧",
    phoneCode: 961,
    label: "Lebanon",
  },
  {
    code: "LS",
    map: "🇱🇸",
    phoneCode: 266,
    label: "Lesotho",
  },
  {
    code: "LR",
    map: "🇱🇷",
    phoneCode: 231,
    label: "Liberia",
  },
  {
    code: "LY",
    map: "🇱🇾",
    phoneCode: 218,
    label: "Libya",
  },
  {
    code: "LI",
    map: "🇱🇮",
    phoneCode: 423,
    label: "Liechtenstein",
  },
  {
    code: "LT",
    map: "🇱🇹",
    phoneCode: 370,
    label: "Lithuania",
  },
  {
    code: "LU",
    map: "🇱🇺",
    phoneCode: 352,
    label: "Luxembourg",
  },
  {
    code: "MG",
    map: "🇲🇬",
    phoneCode: 261,
    label: "Madagascar",
  },
  {
    code: "MW",
    map: "🇲🇼",
    phoneCode: 265,
    label: "Malawi",
  },
  {
    code: "MY",
    map: "🇲🇾",
    phoneCode: 60,
    label: "Malaysia",
  },
  {
    code: "MV",
    map: "🇲🇻",
    phoneCode: 960,
    label: "Maldives",
  },
  {
    code: "ML",
    map: "🇲🇱",
    phoneCode: 223,
    label: "Mali",
  },
  {
    code: "MT",
    map: "🇲🇹",
    phoneCode: 356,
    label: "Malta",
  },
  {
    code: "MH",
    map: "🇲🇭",
    phoneCode: 692,
    label: "Marshall Islands",
  },
  {
    code: "MQ",
    map: "🇲🇶",
    phoneCode: 596,
    label: "Martinique",
  },
  {
    code: "MR",
    map: "🇲🇷",
    phoneCode: 222,
    label: "Mauritania",
  },
  {
    code: "MU",
    map: "🇲🇺",
    phoneCode: 230,
    label: "Mauritius",
  },
  {
    code: "YT",
    map: "🇾🇹",
    phoneCode: 269,
    label: "Mayotte",
  },
  {
    code: "MX",
    map: "🇲🇽",
    phoneCode: 52,
    label: "Mexico",
  },
  {
    code: "FM",
    map: "🇫🇲",
    phoneCode: 691,
    label: "Micronesia",
  },
  {
    code: "MD",
    map: "🇲🇩",
    phoneCode: 373,
    label: "Moldova",
  },
  {
    code: "MC",
    map: "🇲🇨",
    phoneCode: 377,
    label: "Monaco",
  },
  {
    code: "MN",
    map: "🇲🇳",
    phoneCode: 976,
    label: "Mongolia",
  },
  {
    code: "ME",
    map: "🇲🇪",
    phoneCode: 382,
    label: "Montenegro",
  },
  {
    code: "MS",
    map: "🇲🇸",
    phoneCode: 1664,
    label: "Montserrat",
  },
  {
    code: "MA",
    map: "🇲🇦",
    phoneCode: 212,
    label: "Morocco",
  },
  {
    code: "MZ",
    map: "🇲🇿",
    phoneCode: 258,
    label: "Mozambique",
  },
  {
    code: "MM",
    map: "🇲🇲",
    phoneCode: 95,
    label: "Myanmar",
  },
  {
    code: "NA",
    map: "🇳🇦",
    phoneCode: 264,
    label: "Namibia",
  },
  {
    code: "NR",
    map: "🇳🇷",
    phoneCode: 674,
    label: "Nauru",
  },
  {
    code: "NP",
    map: "🇳🇵",
    phoneCode: 977,
    label: "Nepal",
  },
  {
    code: "NC",
    map: "🇳🇨",
    phoneCode: 687,
    label: "New Caledonia",
  },
  {
    code: "NZ",
    map: "🇳🇿",
    phoneCode: 64,
    label: "New Zealand",
  },
  {
    code: "NI",
    map: "🇳🇮",
    phoneCode: 505,
    label: "Nicaragua",
  },
  {
    code: "NE",
    map: "🇳🇪",
    phoneCode: 227,
    label: "Niger",
  },
  {
    code: "NG",
    map: "🇳🇬",
    phoneCode: 234,
    label: "Nigeria",
  },
  {
    code: "NL",
    map: "🇳🇱",
    phoneCode: 31,
    label: "Netherlands",
  },
  {
    code: "NU",
    map: "🇳🇺",
    phoneCode: 683,
    label: "Niue",
  },
  {
    code: "NF",
    map: "🇳🇫",
    phoneCode: 672,
    label: "Norfolk Island",
  },
  {
    code: "MP",
    map: "🇲🇵",
    phoneCode: 1670,
    label: "Northern Mariana Islands",
  },
  {
    code: "KP",
    map: "🇰🇵",
    phoneCode: 850,
    label: "North Korea",
  },
  {
    code: "MK",
    map: "🇲🇰",
    phoneCode: 389,
    label: "North Macedonia",
  },
  {
    code: "NO",
    map: "🇳🇴",
    phoneCode: 47,
    label: "Norway",
  },
  {
    code: "OM",
    map: "🇴🇲",
    phoneCode: 968,
    label: "Oman",
  },
  {
    code: "PK",
    map: "🇵🇰",
    phoneCode: 92,
    label: "Pakistan",
  },
  {
    code: "PW",
    map: "🇵🇼",
    phoneCode: 680,
    label: "Palau",
  },
  {
    code: "PA",
    map: "🇵🇦",
    phoneCode: 507,
    label: "Panama",
  },
  {
    code: "PY",
    map: "🇵🇾",
    phoneCode: 595,
    label: "Paraguay",
  },
  {
    code: "PE",
    map: "🇵🇪",
    phoneCode: 51,
    label: "Peru",
  },
  {
    code: "PH",
    map: "🇵🇭",
    phoneCode: 63,
    label: "Philippines",
  },
  {
    code: "PL",
    map: "🇵🇱",
    phoneCode: 48,
    label: "Poland",
  },
  {
    code: "PT",
    map: "🇵🇹",
    phoneCode: 351,
    label: "Portugal",
  },
  {
    code: "PR",
    map: "🇵🇷",
    phoneCode: 1787,
    label: "Puerto Rico",
  },
  {
    code: "QA",
    map: "🇶🇦",
    phoneCode: 974,
    label: "Qatar",
  },
  {
    code: "CG",
    map: "🇨🇬",
    phoneCode: 242,
    label: "Republic of the Congo",
  },
  {
    code: "RE",
    map: "🇷🇪",
    phoneCode: 262,
    label: "Réunion",
  },
  {
    code: "RO",
    map: "🇷🇴",
    phoneCode: 40,
    label: "Romania",
  },
  {
    code: "RU",
    map: "🇷🇺",
    phoneCode: 70,
    label: "Russia",
  },
  {
    code: "RW",
    map: "🇷🇼",
    phoneCode: 250,
    label: "Rwanda",
  },
  {
    code: "KN",
    map: "🇰🇳",
    phoneCode: 1869,
    label: "Saint Kitts and Nevis",
  },
  {
    code: "LC",
    map: "🇱🇨",
    phoneCode: 1758,
    label: "Saint Lucia",
  },
  {
    code: "VC",
    map: "🇻🇨",
    phoneCode: 1784,
    label: "Saint Vincent and the Grenadines",
  },
  {
    code: "WS",
    map: "🇼🇸",
    phoneCode: 684,
    label: "Samoa",
  },
  {
    code: "SM",
    map: "🇸🇲",
    phoneCode: 378,
    label: "San Marino",
  },
  {
    code: "ST",
    map: "🇸🇹",
    phoneCode: 239,
    label: "São Tomé and Príncipe",
  },
  {
    code: "SA",
    map: "🇸🇦",
    phoneCode: 966,
    label: "Saudi Arabia",
  },
  {
    code: "SN",
    map: "🇸🇳",
    phoneCode: 221,
    label: "Senegal",
  },
  {
    code: "RS",
    map: "🇷🇸",
    phoneCode: 381,
    label: "Serbia",
  },
  {
    code: "SC",
    map: "🇸🇨",
    phoneCode: 248,
    label: "Seychelles",
  },
  {
    code: "SL",
    map: "🇸🇱",
    phoneCode: 232,
    label: "Sierra Leone",
  },
  {
    code: "SG",
    map: "🇸🇬",
    phoneCode: 65,
    label: "Singapore",
  },
  {
    code: "SK",
    map: "🇸🇰",
    phoneCode: 421,
    label: "Slovakia",
  },
  {
    code: "SI",
    map: "🇸🇮",
    phoneCode: 386,
    label: "Slovenia",
  },
  {
    code: "SB",
    map: "🇸🇧",
    phoneCode: 677,
    label: "Solomon Islands",
  },
  {
    code: "SO",
    map: "🇸🇴",
    phoneCode: 252,
    label: "Somalia",
  },
  {
    code: "ZA",
    map: "🇿🇦",
    phoneCode: 27,
    label: "South Africa",
  },
  {
    code: "KR",
    map: "🇰🇷",
    phoneCode: 82,
    label: "South Korea",
  },
  {
    code: "SS",
    map: "🇸🇸",
    phoneCode: 211,
    label: "South Sudan",
  },
  {
    code: "ES",
    map: "🇪🇸",
    phoneCode: 34,
    label: "Spain",
  },
  {
    code: "LK",
    map: "🇱🇰",
    phoneCode: 94,
    label: "Sri Lanka",
  },
  {
    code: "PS",
    map: "🇵🇸",
    phoneCode: 970,
    label: "State of Palestine",
  },
  {
    code: "SD",
    map: "🇸🇩",
    phoneCode: 249,
    label: "Sudan",
  },
  {
    code: "SR",
    map: "🇸🇷",
    phoneCode: 597,
    label: "Suriname",
  },
  {
    code: "SE",
    map: "🇸🇪",
    phoneCode: 46,
    label: "Sweden",
  },
  {
    code: "CH",
    map: "🇨🇭",
    phoneCode: 41,
    label: "Switzerland",
  },
  {
    code: "SY",
    map: "🇸🇾",
    phoneCode: 963,
    label: "Syria",
  },
  {
    code: "TW",
    map: "🇹🇼",
    phoneCode: 886,
    label: "Taiwan",
  },
  {
    code: "TJ",
    map: "🇹🇯",
    phoneCode: 992,
    label: "Tajikistan",
  },
  {
    code: "TZ",
    map: "🇹🇿",
    phoneCode: 255,
    label: "Tanzania",
  },
  {
    code: "TH",
    map: "🇹🇭",
    phoneCode: 66,
    label: "Thailand",
  },
  {
    code: "TG",
    map: "🇹🇬",
    phoneCode: 228,
    label: "Togo",
  },
  {
    code: "TK",
    map: "🇹🇰",
    phoneCode: 690,
    label: "Tokelau",
  },
  {
    code: "TO",
    map: "🇹🇴",
    phoneCode: 676,
    label: "Tonga",
  },
  {
    code: "TT",
    map: "🇹🇹",
    phoneCode: 1868,
    label: "Trinidad and Tobago",
  },
  {
    code: "TN",
    map: "🇹🇳",
    phoneCode: 216,
    label: "Tunisia",
  },
  {
    code: "TR",
    map: "🇹🇷",
    phoneCode: 90,
    label: "Turkey",
  },
  {
    code: "TM",
    map: "🇹🇲",
    phoneCode: 7370,
    label: "Turkmenistan",
  },
  {
    code: "TV",
    map: "🇹🇻",
    phoneCode: 688,
    label: "Tuvalu",
  },
  {
    code: "UG",
    map: "🇺🇬",
    phoneCode: 256,
    label: "Uganda",
  },
  {
    code: "UA",
    map: "🇺🇦",
    phoneCode: 380,
    label: "Ukraine",
  },
  {
    code: "AE",
    map: "🇦🇪",
    phoneCode: 971,
    label: "United Arab Emirates",
  },
  {
    code: "GB",
    map: "🇬🇧",
    phoneCode: 44,
    label: "United Kingdom",
  },
  {
    code: "US",
    map: "🇺🇸",
    phoneCode: 1,
    label: "United States",
  },
  {
    code: "UY",
    map: "🇺🇾",
    phoneCode: 598,
    label: "Uruguay",
  },
  {
    code: "UZ",
    map: "🇺🇿",
    phoneCode: 998,
    label: "Uzbekistan",
  },
  {
    code: "VU",
    map: "🇻🇺",
    phoneCode: 678,
    label: "Vanuatu",
  },
  {
    code: "VA",
    map: "🇻🇦",
    phoneCode: 379,
    label: "Vatican City",
  },
  {
    code: "VE",
    map: "🇻🇪",
    phoneCode: 58,
    label: "Venezuela",
  },
  {
    code: "VN",
    map: "🇻🇳",
    phoneCode: 84,
    label: "Vietnam",
  },
  {
    code: "EH",
    map: "🇪🇭",
    phoneCode: 212,
    label: "Western Sahara",
  },
  {
    code: "YE",
    map: "🇾🇪",
    phoneCode: 967,
    label: "Yemen",
  },
  {
    code: "ZM",
    map: "🇿🇲",
    phoneCode: 260,
    label: "Zambia",
  },
  {
    code: "ZW",
    map: "🇿🇼",
    phoneCode: 26,
    label: "Zimbabwe",
  },
];

export default data;
